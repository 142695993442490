<!--
 * @Description:: 育小帮科技有限公司
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2022-11-04 16:43:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 09:49:25
 * @FilePath: /siana_admin/src/views/order/components/list.vue
-->
<template>
  <div>

    <!-- 搜索区 -->
    <SearchForm
      :columns="columns"
      @search="search" />
    <el-card shadow="never">

      <!-- 表格标题 -->
      <div
        class="tableHeader">
        <div class="left">
          订单管理
        </div>
        <!--操作按钮-->
        <div class="right">
          <!-- <el-button
            type="primary"
            @click="$refs.show.open('add')">添加</el-button> -->
        </div>
      </div>
      <!--
      表格组件
      @setColumns  //获取表头方法
      @selectChange // 勾选方法
      @changePage  // 切换页码方法
     -->
      <Card v-if="phone"
        class="mt10"
        :columns="columns"
        :page-number="pageNumber"
        :page-size="pageSize"
        :total="total"
        :table-loading="tableLoading"
        :table-data="tableData"
        @edit="edit"
        @del="del"
        @changePage="changePage" />
      <Table v-if="!phone"
        class="mt10"
        :columns="columns"
        :page-number="pageNumber"
        :page-size="pageSize"
        :total="total"
        :table-loading="tableLoading"
        :table-data="tableData"
        @edit="edit"
        @del="del"
        @detail="detail"
        @changePage="changePage" />
      <!--表格组件 end-->
      <ModalUpload ref="show"
        @ok="getData" />
    </el-card>


    <el-dialog :visible.sync="detailVisible" 
        :before-close="handleClose"  
        width="760px"
        center
        title="详情"
        append-to-body>

            <!-- <div v-if="curShowProduct">
                <div class="detailItem" v-if="curShowProduct.hasOwnProperty('name')">商品名称：{{curShowProduct.name}}</div>
                <div class="detailItem" v-if="curShowProduct.hasOwnProperty('times')">GPT3.5次数/日：{{ curShowProduct.times >= 0 ? curShowProduct.times : "无限" }}</div>
                <div class="detailItem" v-if="curShowProduct.hasOwnProperty('limitToken')">gpt3.5 token：{{ curShowProduct.limitToken >= 0 ? curShowProduct.limitToken : "无限" }}</div>
                <div class="detailItem" v-if="curShowProduct.hasOwnProperty('suGpt4')">是否支持gpt4：{{ curShowProduct.suGpt4 ? "支持" : "不支持" }}</div>
                <div v-if="curShowProduct.hasOwnProperty('suGpt4')">
                    <div class="detailItem" v-if="curShowProduct.suGpt4">gpt4次数/日: {{ curShowProduct.limitTimesGpt4 >= 0 ? curShowProduct.limitTimesGpt4 : "无限" }}</div>
                    <div class="detailItem" v-if="curShowProduct.suGpt4">gpt4 token：{{ curShowProduct.limitTokenGpt4  >=0 ? curShowProduct.limitTokenGpt4 : "无限"}}</div>
                </div>
            </div> -->
            <div v-if="curShowProduct">
                <div v-if="goodsType==0"> <!-- goodsChargingWallet -->
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('name')">名称：{{curShowProduct.name}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('originalPrice')">原价(￥)：{{Number(curShowProduct.originalPrice)/100}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('realPrice')">实际价格(￥)：{{Number(curShowProduct.realPrice)/100}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('giftAmount')">赠送金额(￥)：{{Number(curShowProduct.giftAmount)/100}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('models')">
                        <span>支持的模型及其可使用的量：</span>
                        <div v-for="(item,index) in curShowProduct.models" :key="index">
                            <div>
                                <span class="detailItem-span">模型名称：{{ item.modelName }}</span>
                                <span class="detailItem-span">数量：{{ item.number }}</span>
                                <span class="detailItem-span">价格(￥)：{{ Number(item.price)/10000 }}</span>
                            </div>
                        </div>
                    </div><div class="detailItem" v-if="curShowProduct.hasOwnProperty('type')">
                        <span>类型：</span>
                    </div>
                </div>
                <div v-if="goodsType==1"> <!-- goodsTrainingText -->
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('name')">名称：{{curShowProduct.name}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('months')">月数：{{curShowProduct.months}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('originalPrice')">原价(￥)：{{Number(curShowProduct.originalPrice)/100}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('realPrice')">实际价格(￥)：{{Number(curShowProduct.realPrice)/100}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('wordNumLimit')">字数限制：{{curShowProduct.wordNumLimit}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('models')">
                        <span>支持的模型及其可使用的量：</span>
                        <div v-for="(item,index) in curShowProduct.models" :key="index">
                            <div>
                                <span class="detailItem-span">模型名称：{{ item.modelName }}</span>
                                <span class="detailItem-span">数量：{{ item.number }}</span>
                                <span class="detailItem-span">价格(￥)：{{ Number(item.price)/10000 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="goodsType==2"> <!-- goodsVip -->
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('name')">名称：{{curShowProduct.name}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('days')">天数：{{ curShowProduct.days }}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('originalPrice')">原价(￥)：{{Number(curShowProduct.originalPrice)/100}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('realPrice')">实际价格(￥)：{{Number(curShowProduct.realPrice)/100}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('models')">
                        <span>支持的模型及其可使用的量：</span>
                        <div v-for="(item,index) in curShowProduct.models" :key="index">
                            <div>
                                <span class="detailItem-span">模型名称：{{ item.modelName }}</span>
                                <span class="detailItem-span">数量：{{ item.number }}</span>
                                <span class="detailItem-span">价格(￥)：{{ Number(item.price)/10000 }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('type')">
                        <span>类型：</span>
                        <span v-for="(value, key, index) in typeListGoodsVip" :key="index">
                            <span v-if="key==curShowProduct.type">{{ value }}</span>
                        </span>
                    </div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('dailyGpt3CountLimit')">每日GPT3.5次数限制：{{curShowProduct.dailyGpt3CountLimit==-1?'无限制':curShowProduct.dailyGpt3CountLimit}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('dailyGpt3TokenLimit')">每日GPT3.5 token限制：{{curShowProduct.dailyGpt3TokenLimit==-1?'无限制':curShowProduct.dailyGpt3TokenLimit}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('dailyGpt4CountLimit')">每日GPT4次数限制：{{curShowProduct.dailyGpt4CountLimit==-1?'无限制':curShowProduct.dailyGpt4CountLimit}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('dailyGpt4TokenLimit')">每日GPT4 token限制：{{curShowProduct.dailyGpt4TokenLimit==-1?'无限制':curShowProduct.dailyGpt4TokenLimit}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('gpt3ContextLimit')">GPT3.5上下文条数限制：{{curShowProduct.gpt3ContextLimit}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('gpt4ContextLimit')">GPT4上下文条数限制：{{curShowProduct.gpt4ContextLimit}}</div>
                </div>
                <div v-if="goodsType==3||goodsType==7"> <!-- goodsOrgVip -->
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('name')">名称：{{curShowProduct.name}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('appNumLimit')">最多应用数量：{{curShowProduct.appNumLimit}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('memberNumLimt')">最多成员数量：{{curShowProduct.memberNumLimt}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('wordNumLimit')">最多训练字数：{{curShowProduct.wordNumLimit}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('yearlyCountLimit')">一年内使用次数限制：{{curShowProduct.yearlyCountLimit}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('years')">年数：{{ curShowProduct.years }}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('days')">天数：{{ curShowProduct.days }}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('giftDays')">赠送天数：{{ curShowProduct.giftDays }}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('originalPrice')">原价(￥)：{{Number(curShowProduct.originalPrice)/100}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('realPrice')">实际价格(￥)：{{Number(curShowProduct.realPrice)/100}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('percent')">分成比例(%)：{{Number(curShowProduct.percent)/10}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('models')">
                        <span>支持的模型及其可使用的量：</span>
                        <div v-for="(item,index) in curShowProduct.models" :key="index">
                            <div>
                                <span class="detailItem-span">模型名称：{{ item.modelName }}</span>
                                <span class="detailItem-span">数量：{{ item.number }}</span>
                                <span class="detailItem-span">价格(￥)：{{ Number(item.price)/10000 }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('type')">
                        <div v-if="goodsType==3">
                            <span>类型：</span>
                            <span v-for="(value, key, index) in typeListGoodsOrgVip1" :key="index">
                                <span v-if="key==curShowProduct.type">{{ value }}</span>
                            </span>
                        </div>
                        <div v-if="goodsType==7">
                            <span>类型：</span>
                            <span v-for="(value, key, index) in typeListGoodsOrgVip2" :key="index">
                                <span v-if="key==curShowProduct.type">{{ value }}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="goodsType==4"> <!-- goodsOrgIncPackage -->
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('name')">名称：{{curShowProduct.name}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('originalPrice')">原价(￥)：{{Number(curShowProduct.originalPrice)/100}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('realPrice')">实际价格(￥)：{{Number(curShowProduct.realPrice)/100}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('countLimit')">使用次数限制：{{curShowProduct.countLimit}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('wordNumLimit')">字数限制：{{curShowProduct.wordNumLimit}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('years')">年数：{{ curShowProduct.years }}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('models')">
                        <span>支持的模型及其可使用的量：</span>
                        <div v-for="(item,index) in curShowProduct.models" :key="index">
                            <div>
                                <span class="detailItem-span">模型名称：{{ item.modelName }}</span>
                                <span class="detailItem-span">数量：{{ item.number }}</span>
                                <span class="detailItem-span">价格(￥)：{{ Number(item.price)/10000 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="goodsType==5"> <!-- goodsOrgMemberVip -->
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('name')">名称：{{curShowProduct.name}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('originalPrice')">原价(￥)：{{Number(curShowProduct.originalPrice)/100}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('realPrice')">实际价格(￥)：{{Number(curShowProduct.realPrice)/100}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('days')">天数：{{ curShowProduct.days }}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('years')">年数：{{ curShowProduct.years }}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('models')">
                        <span>支持的模型及其可使用的量：</span>
                        <div v-for="(item,index) in curShowProduct.models" :key="index">
                            <div>
                                <span class="detailItem-span">模型名称：{{ item.modelName }}</span>
                                <span class="detailItem-span">数量：{{ item.number }}</span>
                                <span class="detailItem-span">价格(￥)：{{ Number(item.price)/10000 }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('type')">
                        <span>类型：</span>
                        <span v-for="(value, key, index) in typeListGoodsOrgMemberVip" :key="index">
                            <span v-if="key==curShowProduct.type">{{ value }}</span>
                        </span>
                    </div>
                </div>
                <div v-if="goodsType==6"> <!-- goodsOrgMemberIncPackage -->
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('name')">名称：{{curShowProduct.name}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('originalPrice')">原价(￥)：{{Number(curShowProduct.originalPrice)/100}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('realPrice')">实际价格(￥)：{{Number(curShowProduct.realPrice)/100}}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('years')">年数：{{ curShowProduct.years }}</div>
                    <div class="detailItem" v-if="curShowProduct.hasOwnProperty('models')">
                        <span>支持的模型及其可使用的量：</span>
                        <div v-for="(item,index) in curShowProduct.models" :key="index">
                            <div>
                                <span class="detailItem-span">模型名称：{{ item.modelName }}</span>
                                <span class="detailItem-span">数量：{{ item.number }}</span>
                                <span class="detailItem-span">价格(￥)：{{ Number(item.price)/10000 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">关闭</el-button>
        </span>

    </el-dialog>

  </div>
</template>

<script>
// 引入组件及方法
// 引入组件
import SearchForm from './search.vue'
import Table from './table.vue'
import Card from './card.vue'
import ModalUpload from './modal.vue'
export default {
  name: 'Yxb30uiIndex',
  components: { SearchForm, Table, ModalUpload, Card },
  // 数据
  data() {
    return {
        goodsType: '',
        typeListGoodsVip: {
            1:'免费版',
            2:'周会员',
            3:'月会员',
            4:'年会员',
            5:'日会员',
            6:'季会员',
            7:'赠送',
        },
        typeListGoodsOrgVip1: {
            0:'免费版',
            1:'体验版',
            2:'企业标准版',
            3:'企业旗舰版',
            4:'团队版',
            5:'企业豪华版',
            6:'私有化部署',
        },
        typeListGoodsOrgVip2: {
            0:'免费版',
            1:'体验版',
            2:'合作基础版',
            3:'合作进阶版',
            4:'合作高级版',
            5:'高校校友版',
        },
        typeListGoodsOrgMemberVip: {
            0:'',
            1:'免费体验版',
            2:'标准版',
            3:'高级版',
        },
      phone: false,
      // 页码
      type: '',
      pageNumber: 1, // 当前页
      // 页面尺寸
      pageSize: 10, // 每页显示条数
      // 当前页
      total: 0, // 数据总数
      // 表格数据
      tableData: [], // 列表数据
      // 表格数据 加载
      tableLoading: false, // 列表加载
      // 筛选项
      queryParams: {}, // 请求参数
      // 切换
      setColumnes: [], // 表头
      // 表头
      columns: [
        {
          // 序号
          title: '序号',
          fixed: 'left',
          minwidth: 40,
          key: 'index',
          align: 'center',
          // 工具提示
          tooltop: false
        },
        {
          title: '商品名称',
          key: 'productName',
          minwidth: 80
          // end
        },
        {
          title: '订单金额',
          key: 'price',
          minwidth: 70,
        },
        {
          title: '购买数量',
          key: 'payNumber',
          minwidth: 60,
        },
        {
          title: '预估成本(￥)',
          key: 'predictCost',
          minwidth: 80,
        },
        {
          title: '平台分成比例(%)',
          key: 'percent',
          minwidth: 80,
        },
        {
          title: '支付状态',
          key: 'state',
          minwidth: 80,
        },
        {
          title: '支付方式',
          key: 'payType',
          minwidth: 80,
        },
        {
          title: '购买类型',
          key: 'buyType',
          minwidth: 60,
        },
        {
          // 姓名
          title: '平台订单号/卡密',
          key: 'id',
          minwidth: 160,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        // {
        //   // 姓名
        //   title: ' 支付消息',
        //   key: 'msg',
        //   minwidth: 130,
        //   // 工具提示
        //   tooltop: true,
        //   isShow: true
        //   // end
        // },
        {
          // 层次
          title: '下单人',
          key: 'userName',
          minwidth: 90,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          title: '手机号',
          key: 'mobile',
          minwidth: 110
          // end
        },
        {
          title: '购买组织名称',
          key: 'buyOrgName',
          minwidth: 100,
          tooltop: true,
          isShow: true
        },
        {
          title: '商品所属组织名称',
          key: 'orgName',
          minwidth: 100,
          tooltop: true,
          isShow: true
        },
        {
          // 层次
          title: '创建时间',
          key: 'createTime',
          minwidth: 150,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          // 层次
          title: '操作',
          key: 'actions',
          minwidth: 170,
          fixed: 'right',
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        }
      ],
      detailVisible:false,
      curShowProduct:null
    }
  },
  //  初始化
  mounted() {
    this.phone = JSON.parse(window.localStorage.getItem('phone'))
    // 数据初始化
    this.getData()
  },

  methods: {
    // 获取数据
    getData() {
      this.$https('ORDERLIST', {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        ...this.queryParams
      }).then(res => {
        this.tableData = res.data.records
        this.total = parseInt(res.data.total)
      })
    },
    // 打开编辑弹窗
    edit(row) {
      this.$https('ORDERCHECKED', {
        orderId: row.id
      }).then(res => {
        if (res.status == 200) {
          console.log(res.data)
        }
      })
    },
    // 删除项
    del(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$https('PRODUCTDELETE', { ids: [row.id] }).then(res => {
          this.getData()
          this.$message.success('删除成功')
        })
      })
    },
    // 翻页
    changePage(data) {
      this.pageNumber = data.pageNumber
      this.pageSize = data.pageSize
      this.getData()
    },
    // 搜索
    search(data) {
      this.pageNumber = 1
      this.queryParams = data
      this.getData()
    },
    //展示订单详情
    detail(item){
      if(item.productJson){
        this.goodsType = item.goodsType
        this.curShowProduct = item.productJson
      }
      this.detailVisible = true
    },
    handleClose(){
      this.detailVisible = false
      this.curShowProduct = null

    }
  }
}
</script>

<style lang="scss" scoped>
.detailItem{
  padding: 8px 20px;
}
.detailItem-span {
    margin-right: 8px;
}
</style>
