<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-04-25 14:19:45
 * @LastEditors: 孙嘉欣
 * @LastEditTime: 2023-03-01 13:00:45
 * @FilePath: \yxb3-new\src\views\platform_basic\lesson\score\index.vue
-->
<template>
  <div>
    <!-- 载入组件 -->
    <transition name="fade-transform" mode="out-in">
      <component :is="view" v-bind="props" @go="linkname" />
    </transition>
    <!-- 载入组件 -->
  </div>
</template>


<script>
// 引入组件及方法
import ActiveList from "./components/list.vue"; //首页
// import ActiveListSchool from "./components/list_teacher_points.vue"; //教学点首页
// import ActiveListManagement from "./components/list_management.vue"; // 管理点首页

export default {
  components: { ActiveList },
  data() {
    return {
      level: 1, // 院校、教学中心、管理点
      view: "", // 组件
      props: {}, //内容
      data: [],//内容
    };
  },
    //  初始化
        mounted() {
    if (this.level === 1) {
      // 获取院校首页
      this.view = "ActiveList";
    } else if (this.level === 2) {
      // 获取教学点首页
      this.view = "ActiveListSchool";
    } else if (this.level === 3) {
      // 获取管理定首页
      this.view = "ActiveListManagement";
    }
  },
  methods: {
    // 获取组件
    linkname(e) {
      this.view = e.view;
      this.props.value = e.value;
    },
  },
};
</script>

<style></style>
